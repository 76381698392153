import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentProjet } from '../../shared/models/documentProjet.model';
import { Projet } from '../../shared/models/projet.model';
import { DocumentService } from '../../shared/services/document.service';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { DocumentHelper } from '../../utils/document-helper';
import { StructureValidationFunction } from '../../utils/structure-validation';
import { ChangeDocumentModalComponent, ConfirmModalComponent } from '../_public_api';
import { DocumentFileManagerService } from '../../shared/services/document-file-manager.service';
import { switchMap } from 'rxjs';
import { EnumScope } from '../../shared/enums/enum.scope';
import { EnumTypeDocument } from '../../shared/enums/enum.typeDocument';
import { Aap } from '../../shared/models/aap.model';
import { EnumDocumentComplementaireType } from '../../shared/enums/enum.document-complementaire-type';
import { DocumentConfigModel } from '../../shared/models/document-config.model';
import { SharedFunction } from '../../utils/sharedFunction';
import { EnumApprobationStatut } from '../../shared/enums/enum.approbation-statut';

@Component({
  selector: 'lib-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() hasCheckbox = false;
  @Input() isChecked = false;
  @Input() isDocAdmin = false;
  @Input() document: DocumentProjet;
  @Input() documentConfigs: DocumentConfigModel[];
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() canUserWrite: boolean;
  @Input() showDownload?: boolean;
  @Input() deleteDisabled?: boolean;
  @Input() showNomAsType?: boolean;
  @Input() isCandidat?: boolean;
  @Input() isSiteAdmin?: boolean;

  @Input() showEdit?: boolean;
  @Input() approbation?: EnumApprobationStatut;
  @Input() showDateAndUploaderName: boolean = true;

  @Output() deleteDocEmitter = new EventEmitter<DocumentProjet>();
  @Output() deleteDocAdminEmitter = new EventEmitter<DocumentProjet>();
  @Output() isCheckedChange = new EventEmitter();

  isScanedDocument: boolean;
  isValidDocument: boolean;
  nomCreateur: string;
  documentName: string;

  constructor(
    public structureValidationFunction: StructureValidationFunction,
    public dialog: MatDialog,
    public showToastrService: ShowToastrService,
    public documentService: DocumentService,
    public documentFileManagerService: DocumentFileManagerService,
    public sharedFunction: SharedFunction
  ) {
    super();
  }

  ngOnInit(): void {
    this.isScanedDocument = this.structureValidationFunction.isScanedDocument(this.document);
    this.isValidDocument = this.structureValidationFunction.isValidDocument(this.document);
    this.nomCreateur = this.getNomCreateurDocument(this.document);
    this.getNomDocument();
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce document.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (result) {
          if (this.isDocAdmin) {
            this.deleteDocAdminEmitter.emit(document);
          } else {
            this.deleteDocument(document);
          }
        }
      });
  }

  deleteDocument(document: DocumentProjet): void {
    this.documentService
      .delete(document, this.isSiteAdmin)
      .pipe(
        this.takeUntilDestroyed(),
        switchMap(() => this.documentFileManagerService.deleteDocument(document))
      )
      .subscribe({
        next: () => {
          this.showToastrService.success('Le document a bien été supprimé.');
          this.deleteDocEmitter.emit(document);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document, this.isSiteAdmin).pipe(this.takeUntilDestroyed()).subscribe();
  }

  onChangeDocument(document: DocumentProjet): void {
    const dialogRef = this.dialog.open(ChangeDocumentModalComponent, {
      data: {
        title: 'Demande de changement',
        structures: this.projet.structures,
        projet: this.projet,
        aap: this.aap,
        documentConfigs: this.documentConfigs,
        textGoButton: 'Valider',
        textReturnButton: 'Annuler',
        icon: true,
        document: document,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (!result) {
          return;
        }
        if (result.projet) {
          document.scope = EnumScope.PROJET;
          document.type = result.projet.nomDocument;
          document.typeDoc = EnumTypeDocument.PROJET;
          document.structureId = '';
        } else {
          document.scope = result.documentAapCible.scope;
          document.type = result.documentAapCible.nomDocument;
          document.typeDoc = result.documentAapCible.scope;
          document.structureId = result.structureCible.id;
        }
        this.documentService.updateDocument(document).subscribe({
          next: () => {
            this.showToastrService.success('Le fichier a bien été déplacé');
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
      });
  }

  checkChange(): void {
    this.isCheckedChange.emit(this.isChecked);
  }

  getNomDocument() {
    return (this.documentName = this.sharedFunction.getDocumentName(this.document));
  }

  protected readonly EnumTypeDocument = EnumTypeDocument;
  protected readonly EnumDocumentComplementaireType = EnumDocumentComplementaireType;
}
