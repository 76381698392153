<div
  *ngIf="document.typeDoc !== EnumTypeDocument.DOCUMENT_NON_FOURNI"
  class="card card--list-file"
  [ngClass]="{
    'card--warning': isScanedDocument && !isValidDocument
  }"
>
  <div class="card__text">
    <div class="me-2">
      <mat-checkbox
        *ngIf="hasCheckbox"
        [checked]="isChecked"
        [(ngModel)]="isChecked"
        (click)="checkChange()"
        [id]="'checkbox__' + document.id"
      ></mat-checkbox>
    </div>
    <div class="card__text-info">
      <span class="card__doc--name">
        {{ documentName }}
      </span>
      <span *ngIf="approbation === 'DOCUMENTS_CONTRAT_APPROUVES'" class="approbation-verified"> Vérifié </span>
      <span *ngIf="approbation === 'DOCUMENTS_CONTRAT_CONTESTES'" class="approbation-contested"> A revoir </span>
      <ng-container *ngIf="showDateAndUploaderName && document.dateCreation">
        <br />
        Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} {{ nomCreateur }}
      </ng-container>
    </div>
  </div>

  <div class="card__actions">
    <ng-content select="[document-actions]"></ng-content>
    <div class="card__action-button-wrapper" *ngIf="showEdit && canUserWrite && !isCandidat && !document?.demandeComplementDocumentId">
      <button type="button" id="change-document" class="card__action-button" (click)="onChangeDocument(document)">
        <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
      </button>
    </div>

    <div class="card__action-button-wrapper">
      <button
        type="button"
        id="download-document"
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error': isScanedDocument && !isValidDocument
        }"
        (click)="downloadDocument(document)"
        [disabled]="!isValidDocument || !isScanedDocument"
        *ngIf="showDownload === undefined || showDownload"
      >
        <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="!isScanedDocument">
        <p>Le test antimalware est en cours.</p>
      </div>
      <div class="card__action-button-tooltip" *ngIf="isScanedDocument && !isValidDocument">
        <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
      </div>
    </div>
    <div class="card__action-button-wrapper" *ngIf="canUserWrite">
      <button
        type="button"
        id="delete-document"
        class="card__action-button"
        (click)="onDeleteDocument(document)"
        [disabled]="deleteDisabled"
      >
        <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="deleteDisabled">
        <p>Vous ne pouvez pas supprimer ce fichier à cette étape.</p>
      </div>
    </div>
  </div>
</div>
