@if (actions && actions.length){
<div class="menu_actions">
  <button
    id="actions-btn"
    class="ds-btn ds-btn--primary btn-correction position-relative"
    placement="bottom-right"
    [ngbPopover]="popCorrection"
  >
    ...
  </button>
  <ng-template #popCorrection>
    <div class="popover_container">
      @for(action of actions; track action) {
      <div class="popover_link">
        <lib-pxl-icon class="icon" icon="icon-action-swap-horiz"></lib-pxl-icon>
        <a (click)="actionClick.emit(action)">{{ action }}</a>
      </div>
      }
    </div>
  </ng-template>
</div>
}
