import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EnumScope } from '../../../shared/enums/enum.scope';
import { EnumStatutDemandeCorrectionTech } from '../../../shared/enums/enum.statutDemandeCorrection';
import { Projet } from '../../../shared/models/_public_models';
import { DemandeCorrection } from '../../../shared/models/demande-correction.model';
import { DemandeModificationHttpService } from '../../../shared/services/demande-modification.http.service';
import { ShowToastrService } from '../../../shared/services/show-toastr.service';
import { ConfirmModalComponent } from '../../_public_api';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lib-demande-modification-candidat',
  templateUrl: './demande-modification-candidat.component.html',
  styleUrls: ['./demande-modification-candidat.component.scss'],
})
export class DemandeModificationCandidatComponent implements OnInit {
  @Input() demande: DemandeCorrection;
  @Input() projet: Projet;
  @Input() canWrite: boolean;
  @Output() correctionSent = new EventEmitter<boolean>();
  correctionForm: UntypedFormGroup;

  readonly confirmModal = {
    title: 'Envoyer mes modifications',
    description: `Avant d'envoyer votre réponse, assurez-vous d'avoir modifié les données du projet ou de la structure concernées par cette demande. Vous ne pourrez plus éditer votre dossier le cas échéant, à moins qu'une nouvelle demande de modification soit ouverte par Bpifrance ou ses partenaires.
     <br/>Confirmez-vous votre action?`,
    textGoButton: 'Confirmer',
    textReturnButton: 'Annuler',
    boldContent: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public showToastrService: ShowToastrService,
    public httpService: DemandeModificationHttpService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.correctionForm = this.formBuilder.group({
      description: [null],
    });
  }

  validateCorrection(): void {
    if (this.correctionForm.valid) {
      const demande = new DemandeCorrection();
      Object.assign(demande, this.demande);
      demande.description = this.correctionForm.controls['description'].value;
      demande.statut = EnumStatutDemandeCorrectionTech.CORRIGE;
      this.httpService.updateCorrection(demande).subscribe({
        next: () => {
          this.showToastrService.success('La correction a été envoyée.');
          this.correctionSent.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  openConfirmModal(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: this.confirmModal,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.validateCorrection();
      }
    });
  }

  protected readonly EnumScope = EnumScope;
}
