import { DocumentProjet, EnumProjetEtape, EnumScope, EnumTypeDocument, Projet, UploadDocModalDocumentModel } from '@shared-ui';
import { DocumentAapModel } from '../shared/models/document-aap.model';

export class DocumentHelper {
  public static readonly TYPE_VIDEO = 'video';
  public static readonly TYPE_B401 = 'B401';
  public static readonly VIDEO_ROOT_PATH = 'videos/';
  public static readonly BUCKET_VIDEO = 'DOCUMENT_PRIVATE_VIDEO';
  public static readonly BUCKET_PRIVATE = 'DOCUMENT_PRIVATE';

  public static buildDocumentProjetWithEtape(
    filename: string,
    projet: Projet,
    etape: EnumProjetEtape,
    document?: UploadDocModalDocumentModel
  ): DocumentProjet {
    const documentProjet = DocumentHelper.buildDocumentProjet(filename, projet, document);
    documentProjet.etape = etape;
    return documentProjet;
  }

  public static buildDocumentProjet(filename: string, projet: Projet, document?: UploadDocModalDocumentModel): DocumentProjet {
    return DocumentHelper.buildDocumentProjetWithProjetId(filename, projet.id, document);
  }

  public static buildDocumentProjetWithProjetId(
    filename: string,
    projetId: string,
    document?: UploadDocModalDocumentModel
  ): DocumentProjet {
    const documentProjet: DocumentProjet = new DocumentProjet();
    documentProjet.typeDoc = this.getTypeDoc(document);
    documentProjet.type = document?.nom ?? '';
    documentProjet.demandeComplementId = document?.demandeComplementId;
    documentProjet.nom = filename;
    documentProjet.projetId = projetId;
    documentProjet.scope = EnumScope.PROJET;
    documentProjet.demandeComplementDocumentId = document?.demandeComplementDocumentId;
    documentProjet.codeGed = document && document.codeGed ? document.codeGed : null;
    documentProjet.domaineGed = document && document.domaineGed ? document.domaineGed : null;
    return documentProjet;
  }

  private static getTypeDoc(document?: UploadDocModalDocumentModel): string {
    return document?.demandeComplementId
      ? EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
      : DocumentHelper.isVideoMediaTypes(document?.mediaTypes)
      ? EnumTypeDocument.MEDIA_VIDEO
      : EnumTypeDocument.PROJET;
  }

  public static getS3Path(projet: Projet, document: DocumentProjet): string {
    const videoDocEnable = DocumentHelper.isVideoDocument(document);
    const root = videoDocEnable ? DocumentHelper.VIDEO_ROOT_PATH : '';
    return root + projet.id + '/' + document.id;
  }

  public static getUploadDocumentType(document: DocumentProjet): string {
    const videoDocEnable = DocumentHelper.isVideoDocument(document);
    return videoDocEnable ? DocumentHelper.TYPE_VIDEO : DocumentHelper.TYPE_B401;
  }

  public static isVideoDocument(document: DocumentProjet): boolean {
    return document.typeDoc === EnumTypeDocument.MEDIA_VIDEO;
  }

  public static isVideoDocumentAap(documentAap?: DocumentAapModel): boolean {
    return documentAap?.mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO) ?? false;
  }

  public static isVideoMediaTypes(mediaTypes?: EnumTypeDocument[]): boolean {
    return mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO) ?? false;
  }

  public static getDocumentBucketType(typeUpload: string): string {
    return typeUpload === DocumentHelper.TYPE_VIDEO ? DocumentHelper.BUCKET_VIDEO : DocumentHelper.BUCKET_PRIVATE;
  }

  public static getDocumentS3FolderPath(document: DocumentProjet, multiDocument?: boolean) {
    if (document.typeDoc == EnumTypeDocument.CONTRAT) {
      return 'contrats/' + document.nom;
    }

    if (multiDocument) {
      return [document.typeDoc.toLowerCase(), document.relatedResourceId].join('/');
    }

    const racines: string[] = [];

    if (document.typeDoc === EnumTypeDocument.MEDIA_VIDEO) {
      racines.push('videos');
    }
    if (this.isScopeAap(document.scope)) {
      racines.push('documents_aaps');
      if (document.aapId != null) {
        racines.push(document.aapId);
      }
    } else if (this.isScopeComite(document.scope)) {
      racines.push('documents_comites');
      if (document.comiteId != null) {
        racines.push(document.comiteId);
      }
    } else {
      racines.push(document.projetId);
    }

    if (this.isScopeStructure(document.scope)) {
      racines.push(document.structureId);
    }

    if (document.typeDoc == EnumTypeDocument.RELEVE_DECISION) {
      racines.push('releve_decision');
    }
    if (document.typeDoc == EnumTypeDocument.CAHIER_CHARGES) {
      racines.push('cahier_charges');
    }
    if (document.typeDoc == EnumTypeDocument.JUSTIFICATIF_FLAMINEM) {
      racines.push('justificatif_connaissance_client');
    } else if (document.typeDoc == EnumTypeDocument.RIB) {
      racines.push('rib');
    } else if (document.typeDoc == EnumTypeDocument.JUSTIFICATIF_VALIDATION_RIB) {
      racines.push('justificatif_validation_rib');
    } else if (document.typeDoc == EnumTypeDocument.RAPPORT_INSTRUCTION) {
      racines.push('rapport_instruction');
    } else if (document.typeDoc == EnumTypeDocument.NOTATION_EVALUATION) {
      racines.push('evaluations');
    } else if (document.typeDoc == EnumTypeDocument.NOTATION_AUDITION) {
      racines.push('auditions');
    } else if (document.typeDoc == EnumTypeDocument.DEMANDE_CORRECTION) {
      racines.push('demande_correction');
    } else if (document.typeDoc == EnumTypeDocument.PV) {
      racines.push('pv');
    } else if (document.typeDoc == EnumTypeDocument.DPM) {
      racines.push('dpm');
    }

    // add others such as notation if needed

    racines.push(document.id);

    return racines.join('/');
  }

  public static getDocumentS3FilePath(document: DocumentProjet) {
    return this.getDocumentS3FolderPath(document) + '/' + document.nom;
  }

  private static isScopeStructure(scope: EnumScope): boolean {
    return scope === EnumScope.STRUCTURE || scope === EnumScope.STRUCTURE_ACTIONARIAL;
  }

  private static isScopeAap(scope: EnumScope): boolean {
    return scope === EnumScope.AAP;
  }

  private static isScopeComite(scope: EnumScope): boolean {
    return scope === EnumScope.COMITE;
  }
  /*
   * Affiche le nom du créateur du Document
   * */
  static getNomCreateurDocument(document: DocumentProjet): string {
    if (document.nomCreateur && document.nomCreateur !== 'undefined undefined' && document.nomCreateur.length > 0) {
      return 'par ' + document.nomCreateur;
    } else {
      return '';
    }
  }
}
