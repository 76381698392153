import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from '../../shared/models/donnees-financieres.model';

@Component({
  selector: 'lib-cancel-save-validate-modify-buttons',
  templateUrl: './cancel-save-validate-modify-buttons.component.html',
  styleUrls: ['./cancel-save-validate-modify-buttons.component.css'],
})
export class CancelSaveValidateModifyButtonsComponent {
  @Input() public status: Status = Status.NON_VALIDE;
  @Input() public displayCancelButton = true;
  @Input() public disabledAllButtons = false;

  @Output() private cancelEventEmitter = new EventEmitter();
  @Output() private saveEventEmitter = new EventEmitter();
  @Output() private validateEventEmitter = new EventEmitter();
  @Output() private updateEventEmitter = new EventEmitter();

  protected readonly Status = Status;

  protected onCancel() {
    this.cancelEventEmitter.emit();
  }

  protected onSave() {
    this.saveEventEmitter.emit();
  }

  protected onValidate() {
    this.validateEventEmitter.emit();
  }

  protected onUpdate() {
    this.updateEventEmitter.emit();
  }
}
