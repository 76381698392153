import { EnumScope } from '../enums/enum.scope';
import { DemandeCorrectionInterface } from '../interfaces/demande-correction.interface';
import { Auteur } from './auteur.model';
import { Contact } from './contact.model';
import { DemandeCorrectionResponse } from './demande-correction-response.model';
import { DocumentDemandeModification } from './document-demande-modification.model';
import { Structure } from './structure.model';

export class DemandeCorrection implements DemandeCorrectionInterface {
  id: string;
  description: string;
  statut: string;
  dateCreation?: Date;
  dateModification?: Date;
  projetId: string;
  auteurs?: Auteur[] = [];
  scope: EnumScope;
  structures?: Structure[] = [];
  structureIds: string[] = [];
  contactsANotifierEmails: string[] = [];
  contactsANotifier?: Contact[] = [];
  modificationDemandeeDateTime: Date;
  modificationDemandeeDateHour: string;
  documents: DocumentDemandeModification[] = [];
  correction?: DemandeCorrectionResponse;
  completudeSecondNiveauDocumentIds?: string[] = [];
}
