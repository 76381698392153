import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Aap,
  EnumActivite,
  EnumFeatureFlipping,
  EnumPermissionUtilisateur,
  FeatureFlagService,
  minSelectedCheckboxes,
  PermissionUtils,
  Projet,
  Utilisateur,
} from '@shared-ui';

export interface AutoAffectationModalData {
  projets: Projet[];
  aap: Aap;
  user: Utilisateur;
  withIntervenantPIOption: boolean;
  withReferentOption: boolean;
}

@Component({
  selector: 'lib-auto-affect-modal',
  templateUrl: './auto-affectation-modal.component.html',
  styleUrls: ['./auto-affectation-modal.component.scss'],
})
export class AutoAffectationModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  selectedProjets: Projet[];
  form: FormGroup<{
    activites: FormArray<FormControl<boolean | null>>;
    referent: FormControl<boolean | null>;
    intervenantPI: FormControl<boolean | null>;
  }>;
  activites: EnumActivite[] = [];
  showIntervenantPI = false;
  showReferent = false;

  constructor(
    public dialogRef: MatDialogRef<AutoAffectationModalComponent>,
    private formBuilder: FormBuilder,
    private featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public modalData: AutoAffectationModalData
  ) {}

  ngOnInit(): void {
    if (this.modalData.projets) {
      this.selectedProjets = this.modalData.projets;
    }
    this.createForm();

    this.activites = this.getAuthorizedActivities();
    this.activites.forEach(() => this.form.controls.activites.push(new FormControl(false)));

    this.showIntervenantPI = this.modalData.withIntervenantPIOption && this.activites.includes(EnumActivite.INSTRUCTION);
    this.showReferent = this.modalData.withReferentOption;
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      activites: this.formBuilder.array<boolean>([], minSelectedCheckboxes(1)),
      referent: [false, [Validators.required]],
      intervenantPI: [{ value: false, disabled: true }, [Validators.required]],
    });

    this.form.controls.activites.valueChanges.subscribe(activites => {
      const instructionIndex = this.activites.indexOf(EnumActivite.INSTRUCTION);
      if (instructionIndex !== -1) {
        if (activites[instructionIndex]) {
          this.form.controls.intervenantPI.enable();
        } else {
          this.form.controls.intervenantPI.disable();
          this.form.controls.intervenantPI.setValue(false);
        }
      }
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const activites = (this.form.controls.activites.value ?? [])
      .map((selected, index) => (selected ? this.activites[index] : null))
      .filter(Boolean);

    const projetIds = this.selectedProjets.map(project => project.id);
    const referent = this.form.controls.referent.value;
    const intervenantPI = this.form.controls.intervenantPI.value;

    this.dialogRef.close({ projetIds, activites, referent, intervenantPI });
  }

  getAuthorizedActivities(): EnumActivite[] {
    return EnumActivite.all()
      .filter(activite => this.featureFlagService.featureOn(this.getFeatureFromActivity(activite)))
      .filter(
        activite =>
          (activite !== EnumActivite.VERROUILLAGE || this.hasActivitePermission(EnumActivite.VERROUILLAGE)) &&
          (activite !== EnumActivite.ELIGIBILITE ||
            (this.modalData.aap.eligibilitePresent && this.hasActivitePermission(EnumActivite.ELIGIBILITE))) &&
          (activite !== EnumActivite.OPPORTUNITE ||
            (this.modalData.aap.notationEvaluationPresent && this.hasActivitePermission(EnumActivite.OPPORTUNITE))) &&
          (activite !== EnumActivite.AUDITION ||
            (this.modalData.aap.notationAuditionPresent && this.hasActivitePermission(EnumActivite.AUDITION))) &&
          (activite !== EnumActivite.INSTRUCTION ||
            (this.modalData.aap.instructionPresent && this.hasActivitePermission(EnumActivite.INSTRUCTION))) &&
          (activite !== EnumActivite.CONTRACTUALISATION ||
            (this.modalData.aap.contractualisationPresent && this.hasActivitePermission(EnumActivite.CONTRACTUALISATION))) &&
          (activite !== EnumActivite.SUIVI || this.hasActivitePermission(EnumActivite.SUIVI))
      );
  }

  hasActivitePermission(activite: EnumActivite) {
    switch (activite) {
      case EnumActivite.VERROUILLAGE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.PROJET_VERROUILLAGE_WRITE],
          this.modalData.aap
        );
      case EnumActivite.ELIGIBILITE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.PROJET_ELIGIBILITE_WRITE],
          this.modalData.aap
        );
      case EnumActivite.OPPORTUNITE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_WRITE_ALL,
            EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE,
            EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE_ALL,
          ],
          this.modalData.aap
        );
      case EnumActivite.AUDITION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_WRITE_ALL,
            EnumPermissionUtilisateur.PROJET_AUDITION_WRITE,
            EnumPermissionUtilisateur.PROJET_AUDITION_WRITE_ALL,
          ],
          this.modalData.aap
        );
      case EnumActivite.INSTRUCTION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_SELECTION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_SELECTION_WRITE_ALL,
            EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
            EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE_ALL,
          ],
          this.modalData.aap
        );
      case EnumActivite.CONTRACTUALISATION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_WRITE],
          this.modalData.aap
        );
      case EnumActivite.SUIVI:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.SUIVI_PROJET_WRITE],
          this.modalData.aap
        );
      default:
        return false;
    }
  }

  getFeatureFromActivity(activity: EnumActivite): EnumFeatureFlipping {
    switch (activity) {
      case EnumActivite.INSTRUCTION:
        return EnumFeatureFlipping.INSTRUCTION_APPROFONDIE;
      case EnumActivite.SUIVI:
        return EnumFeatureFlipping.SUIVI_DES_PROJETS;
      default:
        return EnumFeatureFlipping[activity as keyof typeof EnumFeatureFlipping];
    }
  }

  protected readonly EnumActivite = EnumActivite;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
}
