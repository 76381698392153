import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent {
  /**
   * @todo: change to list of actions {id, label}
   */
  @Input() actions: string[];

  @Output() actionClick = new EventEmitter<string>();
}
