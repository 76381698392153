import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Aap,
  ConfirmModalComponent,
  DocumentHelper,
  DocumentProjet,
  EnumScanDocument,
  EnumScope,
  EnumTypePartenaire,
  Environment,
  FicheCom,
  FicheComHelperService,
  FormComponentInterface,
  InformationProjetFicheCom,
  LOGO_FILE_SIZE,
  Projet,
  DocumentService,
  ShowToastrService,
  SignedUrlResponseModel,
  UploadDocumentHttpService,
  Utilisateur,
  resizeImage,
} from '@shared-ui';
import { Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'lib-fiche-com-info-projet',
  templateUrl: './fiche-com-info-projet.component.html',
  styleUrls: ['./fiche-com-info-projet.component.scss'],
})
export class FicheComInfoProjetComponent implements OnInit, FormComponentInterface {
  protected readonly EnumTypePartenaire = EnumTypePartenaire;

  maxLengthDescription = 100;
  maxLengthObjectifs = 1000;
  maxLengthDescriptionConsortium = 300;
  maxLengthLegendeCredit = 50;
  imgWidth = 340;
  imgHeight = 226;
  oneMo = 1048576;
  ratio = false;

  formSubmitted = false;
  savedInfoProjet: InformationProjetFicheCom;
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() infoProjetForm: FormGroup;
  @Input() ficheCom: FicheCom = new FicheCom();
  @Input() canUserWrite: boolean;
  @Input() utilisateur: Utilisateur;
  @Input() listLogoProjet: DocumentProjet[] = [];
  @Input() isValideFicheCom = false;
  @Output() saveFicheCom = new EventEmitter();

  fileTooBig = false;
  fileNotSupported = false;
  fileToUpload: File;

  docLogosPublicBaseUrl: string;
  imgInfoProjet: DocumentProjet = new DocumentProjet();
  imagePath = '';

  readonly SAFE = (EnumScanDocument as any)[EnumScanDocument.SAFE.toString()];
  readonly UNSAFE = (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()];
  logosChange = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(this.infoProjetForm.dirty || this.logosChange);
  }

  constructor(
    @Inject('environment') private environment: Environment,
    public matDialog: MatDialog,
    private uploadDocumentService: UploadDocumentHttpService,
    private projetService: DocumentService,
    private showToastrService: ShowToastrService,
    public resizeImage: resizeImage
  ) {}

  ngOnInit(): void {
    this.docLogosPublicBaseUrl = this.environment.docLogosPublicBaseUrl;
    this.setForm();
    this.feedInfoProjetForm();
    this.imgInfoProjet = this.listLogoProjet?.filter((doc: DocumentProjet) => doc.scope === EnumScope.PROJET)[0] || new DocumentProjet();
    this.setImagePath();
    this.infoProjetForm.patchValue({ logo: this.imgInfoProjet.nom });
  }

  showValidIcon(): boolean {
    return (
      this.ficheCom?.informationProjet?.pathS3Image !== null &&
      this.ficheCom?.informationProjet?.pathS3Image !== undefined &&
      this.ficheCom?.informationProjet?.description !== undefined &&
      this.ficheCom.informationProjet.fonctionnementConsortium !== undefined &&
      this.ficheCom.informationProjet.legendeCredit !== undefined &&
      this.ficheCom.informationProjet.objectifs !== undefined
    );
  }

  setImagePath(): void {
    this.imagePath = this.docLogosPublicBaseUrl + FicheComHelperService.getDocumentPath(this.projet.id, undefined, this.imgInfoProjet.nom);
  }

  private setForm(): void {
    this.infoProjetForm = new FormGroup({
      description: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
      objectifs: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
      fonctionnementConsortium: new FormControl<string>(
        { value: '', disabled: !this.canUserWrite || this.isValideFicheCom },
        Validators.required
      ),
      logo: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
      legendeCredit: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
    });
  }

  feedInfoProjetForm(): void {
    this.infoProjetForm.patchValue(this.ficheCom?.informationProjet);
    if (this.projet?.partenaireType === 'INDIV') {
      this.infoProjetForm.get('fonctionnementConsortium')?.setValidators(null);
    }
    this.savedInfoProjet = this.infoProjetForm.getRawValue();
  }

  onInfoProjetSubmitted(): void {
    this.formSubmitted = true;
    this.infoProjetForm.markAllAsTouched();

    if (this.infoProjetForm.valid) {
      const infoProjet = this.infoProjetForm.getRawValue() as InformationProjetFicheCom;
      this.savedInfoProjet = infoProjet;
      this.ficheCom.informationProjet = this.savedInfoProjet;
      this.ficheCom.informationProjet.pathS3Image = {
        bucketName: 'logo_bucket',
        httpUrl: this.environment.docLogosPublicBaseUrl + FicheComHelperService.getDocumentPath(this.projet.id, undefined, infoProjet.logo),
      };

      this.saveFicheCom.emit(this.ficheCom);
      this.infoProjetForm.markAsPristine();
      this.logosChange = false;
    }
  }

  cancel(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annuler les modifications en cours',
        description: `<p>En confirmant l'action, Vous allez perdre vos modification en cours.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.savedInfoProjet?.description != null) {
          this.infoProjetForm.setValue(this.savedInfoProjet);
          this.infoProjetForm.patchValue({ logo: this.imgInfoProjet.nom });
          this.infoProjetForm.markAsPristine();
          return;
        }
        this.infoProjetForm.reset({
          description: '',
          objectifs: '',
          fonctionnementConsortium: '',
          logo: '',
          legendeCredit: '',
        });
      }
    });
  }

  onUpload(target: EventTarget | null | undefined): void {
    const files = (<HTMLInputElement>target)?.files;

    this.fileTooBig = false;
    this.fileNotSupported = false;
    this.fileToUpload = files!.item(0)!;

    if (this.fileToUpload.size >= LOGO_FILE_SIZE * this.oneMo) {
      this.fileTooBig = true;
    } else if (this.fileToUpload && this.fileToUpload.type !== 'image/jpeg' && this.fileToUpload.type !== 'image/png') {
      this.fileNotSupported = true;
    } else {
      this.resizeImage
        .processImage(this.fileToUpload, this.imgWidth, this.imgHeight)
        .pipe(
          switchMap((file: File | null) => {
            if (file) {
              this.fileToUpload = new File([file], file.name, { type: file.type });
              Object.assign(this.imgInfoProjet, {
                nom: this.fileToUpload.name,
                projetId: this.projet.id,
                scope: EnumScope.PROJET,
                typeDoc: 'MEDIA_IMAGE',
              });

              if (resizeImage.getWidth() < this.imgWidth / 2 || resizeImage.getHeight() < this.imgHeight * 2) {
                this.ratio = true;
              } else {
                this.ratio = false;
              }

              return this.projetService.createDocument(this.imgInfoProjet);
            } else {
              this.showToastrService.error('Failed to process image.');
              throw new Error('Failed to process image.');
            }
          }),
          switchMap((rep: HttpResponse<DocumentProjet>) => {
            this.imgInfoProjet = rep.body!;
            const path = FicheComHelperService.getDocumentPath(this.projet.id, undefined);

            return this.uploadDocumentService.getValueForLogoUploadFicheDeCom(path, this.fileToUpload.name, this.imgInfoProjet.id);
          }),
          switchMap((response: HttpResponse<{ url: string }>) => {
            const url = response.body!.url!;
            return this.uploadDocumentService.uploadLogo(url, this.fileToUpload);
          })
        )
        .subscribe({
          next: () => {
            this.infoProjetForm.get('logo')?.setValue(this.fileToUpload?.name);
            this.setImagePath();
            this.logosChange = true;
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isScanedDocument(document: DocumentProjet): boolean {
    if (document?.scan === this.SAFE || document?.scan === this.UNSAFE) {
      return true;
    }
    return false;
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    const path = FicheComHelperService.getDocumentPath(this.projet.id, document.structureId, document.nom);
    this.uploadDocumentService
      .getValueForDocLogoDownload(path)
      .pipe(take(1))
      .subscribe({
        next: (response: HttpResponse<SignedUrlResponseModel>) => {
          const url = response.body?.url;
          window.open(url);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Affiche la modale pour supprimer un document
   * */

  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (!result) {
        return;
      }
      this.projetService.deleteDocument(this.projet.id, document.id).subscribe({
        next: () => {
          this.showToastrService.success('Le fichier a bien été supprimé');
          this.imgInfoProjet = new DocumentProjet();
          this.setImagePath();
          if (document.scan === (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()]) {
            return;
          }
          const path = FicheComHelperService.getDocumentPath(this.projet.id, undefined, document.nom);
          this.uploadDocumentService.deleteDocLogo(path).subscribe({
            next: () => {
              this.ficheCom.informationProjet.pathS3Image = null;
              this.infoProjetForm.get('logo')?.setValue('');
              this.infoProjetForm.get('logo')?.markAsTouched();
              this.saveFicheCom.emit(this.ficheCom);
              this.logosChange = true;
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }

  isFormDirty(): boolean {
    return this.infoProjetForm.dirty;
  }
}
