<div
  *ngIf="document.type === documentConfig.nomDocument"
  class="card card--list-file"
  style="
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  "
  [ngClass]="{
    'card--warning': structureValidationFunction.isScanedDocument(document) && !structureValidationFunction.isValidDocument(document)
  }"
>
  <div style="display: flex; align-items: baseline">
    <div>
      <p class="card__title">{{ document.nom }}</p>
      <p class="card__text">Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} par {{ document.nomCreateur }}</p>
    </div>
  </div>

  <div class="card__actions" style="display: flex; justify-content: space-between; align-items: center">
    <div class="card__action-button-wrapper">
      <div class="document_checkbox">
        <label class="ds-radio--alternative white_button" [ngClass]="{ 'ds-radio--alternative-success': statut === VALIDE }">
          <input
            class="ds-radio__input"
            type="radio"
            (click)="changeDocumentStatut(VALIDE)"
            [checked]="valideChecked"
            [disabled]="!canUserWrite"
          />
          <span class="ds-radio__label">
            <span>{{ EnumValidation.VALIDE }}</span>
          </span>
        </label>

        <label class="ds-radio--alternative white_button" [ngClass]="{ 'ds-radio--alternative-error': statut === INVALIDE }">
          <div style="display: flex; justify-content: end" *ngIf="commentaireDocumentInvalide">
            <button
              class="card__action-button comment-button invalide-doc"
              matTooltipClass="tooltip"
              type="button"
              (click)="openDocumentInvadeBox($event)"
              [matTooltipPosition]="'right'"
              [matTooltip]="commentaireDocumentInvalide"
              [disabled]="!canUserWrite"
            >
              <lib-pxl-icon class="icon" icon="icon-comment"></lib-pxl-icon>
            </button>
          </div>
          <div>
            <input
              class="ds-radio__input"
              type="radio"
              (click)="openInvalidateModal()"
              [checked]="invalideChecked"
              [disabled]="!canUserWrite"
            />
            <span class="ds-radio__label" matTooltipClass="tooltip" [matTooltip]="EnumDocumentInvalide.toString(motifDocumentInvalide)">
              {{ EnumValidation.INVALIDE }}
            </span>
          </div>
        </label>
      </div>
    </div>

    <div class="card__action-button-wrapper">
      <button
        type="button"
        id="download-document"
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error':
            structureValidationFunction.isScanedDocument(document) && !structureValidationFunction.isValidDocument(document)
        }"
        (click)="downloadDocument(document)"
        [disabled]="
          !structureValidationFunction.isValidDocument(document) || !structureValidationFunction.isScanedDocument(document) || !canUserWrite
        "
      >
        <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="!structureValidationFunction.isScanedDocument(document)">
        <p>Le test antimalware est en cours.</p>
      </div>
      <div
        class="card__action-button-tooltip"
        *ngIf="structureValidationFunction.isScanedDocument(document) && !structureValidationFunction.isValidDocument(document)"
      >
        <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
      </div>
    </div>

    <div class="card__action-button-wrapper">
      <button
        type="button"
        id="delete-document"
        class="card__action-button"
        (click)="openConfirmationDeleteModal(document)"
        [disabled]="!canUserWrite"
      >
        <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
      </button>
    </div>
  </div>
</div>
