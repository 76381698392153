import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-depense-projet-upload-error-modal',
  templateUrl: './depense-projet-upload-error-modal.component.html',
  styleUrl: './depense-projet-upload-error-modal.component.scss',
})
export class DepenseProjetUploadErrorModalComponent {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<DepenseProjetUploadErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {
    modalData.boldContent = modalData.boldContent ?? true;
  }
}
