<div id="card-correction" class="card card-correction">
  <section class="modification-section modification-infos">
    <div class="d-flex flex-wrap correction-header">
      <div class="d-flex flex-wrap">
        <p class="card__title">Modification demandée</p>
        <div class="d-flex">
          <div class="modification-tag tag--blue">{{ EnumScope.toString(demande.scope, true) }}</div>
          <div class="modification-tag">Attendue avant le {{ demande.modificationDemandeeDateTime | date : "dd/MM/yyyy à HH'h'mm" }}</div>
        </div>
      </div>
      <span *ngIf="isArchived" [ngClass]="{ valide: getCorrectionStatus(demande) === CORRECTION_VALIDE_LABEL }">{{
        getCorrectionStatus(demande)
      }}</span>
    </div>
    <p class="card__subtitle">
      <ng-content select="[auteur]"></ng-content>
    </p>
    <p class="card__subtitle" *ngIf="isArchived">
      {{ getCorrectionStatus(demande) === CORRECTION_VALIDE_LABEL ? 'Validée' : 'Rejetée' }}
      <span *ngIf="demande.auteurs && demande.auteurs[1]">par {{ demande.auteurs[1].prenom }} {{ demande.auteurs[1].nom }}, </span>le
      {{ demande.dateModification | date : "dd/MM/yyyy à HH'H'mm" }}
    </p>
    <p class="card__subtitle" *ngIf="demande?.contactsANotifier?.length">Envoyée à {{ getSentToContactsText() }}</p>
    <button class="button-voir-plus" *ngIf="isLongContacts" (click)="afficherPlusContacts(); $event.stopPropagation()">
      Voir {{ readMoreContacts ? 'moins' : 'plus' }}
    </button>

    <p class="card__description">{{ demande.description }}</p>
  </section>

  <section class="modification-section modification-structures" *ngIf="demande?.structures?.length">
    <p class="card__title">Structure(s) notifiée(s)</p>
    <p class="card__subtitle">
      {{ getSentToStructuresText() }}
    </p>
    <button class="button-voir-plus" *ngIf="isLongStructures" (click)="afficherPlusStructures(); $event.stopPropagation()">
      Voir {{ readMoreStructures ? 'moins' : 'plus' }}
    </button>
  </section>

  <section class="modification-section modification-files" *ngIf="demande.documents.length > 0">
    <p class="card__title">Fichier(s) joint(s)</p>
    <p class="card__subtitle" *ngIf="demande.scope === EnumScope.PROJET">Toutes les structures ont accès aux documents ci-dessous.</p>
    <ng-container>
      <div class="div_list_documents" (click)="$event.stopPropagation()">
        <div *ngFor="let document of demande.documents" class="card__action-button-wrapper">
          <div
            class="div_document_item"
            [ngClass]="{
              'card__action-button--error': isScannedDocument(document) && !isValidDocument(document),
              disabled: !isValidDocument(document) || !isScannedDocument(document)
            }"
            (click)="downloadDocument(document)"
          >
            <lib-pxl-icon class="icon_document" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
            <span class="span_nom_document">{{ document.nom }}</span>
          </div>
          <div class="card__action-button-tooltip" *ngIf="!isScannedDocument(document)">
            <p>Le test antimalware est en cours.</p>
          </div>
          <div class="card__action-button-tooltip" *ngIf="isScannedDocument(document) && !isValidDocument(document)">
            <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
          </div>
        </div>
      </div>
    </ng-container>
  </section>

  <!-- Section d'actions (champs textes et boutons) -->
  <ng-content select="[action-section]"></ng-content>
  <!-- Fin section d'actions (champs textes et boutons) -->
</div>
