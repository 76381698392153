<form [formGroup]="tachesForm" class="table-container mat-elevation-z0" tabindex="0">
  <mat-table #table [dataSource]="dataSource" class="depenses-table" [ngClass]="{ isFull: isFullScreen }">
    <ng-container *ngFor="let col of columns; let colIndex = index" [matColumnDef]="col.def" [sticky]="col.sticky">
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="{ filterActive: filterActive.includes(col.def) }"
        [ngStyle]="{ 'max-width': col.width, 'min-width': col.width, 'vertical-align': 'middle' }"
      >
        <lib-depenses-projet-table-header
          *ngIf="dataSource.data && dataSource.data.length > 0"
          [cellParam]="col"
          [columnData]="{ map: filtersMap, col: col.def }"
          [updateFilter]="sourceFiltered"
          [infoText]="col.info ? col.info : ''"
          [externalChange]="externalChange"
          (filterChange)="onFilterChange($event)"
          (actionClicked)="onActionClicked($event)"
          (selectAllClicked)="onSelectAll($event)"
        />
      </th>
      <td
        mat-cell
        *matCellDef="let element; let index = index"
        [attr.rowspan]="col.span ? spans[index]?.[col.def] : 1"
        [style.display]=" col.span ? (spans[index]?.[col.def] ? '' : 'none') : 'table-cell'"
        [ngStyle]="{
          'background-color': getBackgroundColor(col, element, index),
          'max-width': col.width,
          'min-width': col.width,
          'vertical-align': 'middle',
        }"
        [ngClass]="{
        commentaire: col.defNote ? element[col.defNote]?.value : null,
        'row-bright' : element['action']?.selected,
        }"
        (click)="setCellEditable(element, col.def); cellSelected(col, element)"
      >
        <div
          [ngClass]="{ default: col.def !== 'action', action: col.def === 'action' }"
          matTooltip="{{ col.cellInfo ? element[col.cellInfo]?.value : '' }}"
          matTooltipClass="tooltip-multiline"
        >
          <span *ngIf="readOnly || (!col.editable && !col.selectableEnable && !col.checkbox) || !element[col.def]?.editable">
            {{ element[col.def]?.value | formatNumber : col }}
          </span>
          <div *ngIf="!readOnly">
            <input
              *ngIf="element[col.def]?.editable && col.editable"
              type="{{ element[col.def].typeField }}"
              libAutoFocus
              [value]="element[col.def].value"
              (change)="element[col.def].value = $any($event.target).value; onChange(false)"
              (blur)="element[col.def].editable = false"
            />
            <select
              *ngIf="element[col.def]?.editable && col.selectableEnable"
              (change)="element[col.def].value = $any($event.target).value; onChange(false)"
              (blur)="element[col.def].editable = false"
              libAutoFocus
            >
              <option *ngFor="let option of col.selectableEnable" [value]="option" [selected]="option === element[col.def].value">
                {{ option }}
              </option>
            </select>
            <div style="width: 100%; display: flex; justify-content: center">
              <mat-checkbox
                *ngIf="col.checkbox"
                [value]="element[col.def]"
                [checked]="element['action'].selected"
                (change)="element[col.def].selected = $event.checked"
              ></mat-checkbox>
            </div>
          </div>
          <div
            class="merge-input-note"
            *ngIf="
              (col.defNote && element[col.defNote]?.value) ||
              (element[col.def] && element[col.def]?.metaData?.note?.commentaire?.data) ||
              (element[col.def] && element[col.def]?.metaData?.commentaire?.data)
            "
          >
            <lib-pxl-icon class="icon-info" icon="icon-notes" />
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span *ngIf="colIndex === 0"> {{ 'Total' }}</span>
        <span *ngIf="col.total"> {{ getTotalCost(col) | number : '1.2-2' }} </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="disColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: disColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="disColumns"></tr>
  </mat-table>
  <mat-paginator [length]="dataSource.data.length" aria-label="Select page of periodic elements"> </mat-paginator>
</form>
