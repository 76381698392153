import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-years-buttons',
  templateUrl: './years-buttons.component.html',
  styleUrls: ['./years-buttons.component.scss'],
})
export class YearsButtonsComponent {
  @Input() public isRemoveYearButtonDisabled = true;
  @Output() private removeYearEmitter = new EventEmitter();
  @Output() private addYearEmitter = new EventEmitter();

  protected onRemoveYear() {
    this.removeYearEmitter.emit();
  }

  protected onAddYear() {
    this.addYearEmitter.emit();
  }
}
