import { EnumScanDocument } from '../enums/enum.scanDocument';
import { EnumScope } from '../enums/enum.scope';
import { ControlesValiditeDocumentInterface, DocumentProjetInterface } from '../interfaces/documentProjet.interface';
import { CompletudeSecondNiveauDocument } from './completude-second-niveau-document.model';
import { Eligibilite } from './eligibilite.model';
import { Verrouillage } from './verrouillage.model';
import { EnumProjetEtape } from '../enums/enumProjetEtape';
import { EnumValidation } from '../enums/enum.validation';
import { EnumDocumentInvalide } from '../enums/enum.documentInvalide';

export class DocumentProjet implements DocumentProjetInterface {
  id: string;
  nom: string;
  type: string;
  typeDoc: string;
  scope: EnumScope;
  scan: EnumScanDocument;
  raisonRefus: string;
  createur: string;
  projetId: string;
  poleId: string;
  structureId: string;
  etapeCleId?: string;
  dateCreation: Date;
  dateModification: Date;
  nomCreateur: string;
  etape: EnumProjetEtape;
  verrouillage: Verrouillage = new Verrouillage();
  eligibilite: Eligibilite = new Eligibilite();
  file: File;
  demandeComplementId?: string;
  demandeComplementDocumentId?: string;
  informationBancaireId?: string;
  completudeSecondNiveauDocument: CompletudeSecondNiveauDocument = new CompletudeSecondNiveauDocument();
  codeGed: string | null;
  stockerGed: boolean;
  domaineGed: string | null;
  commentaire: string | null;
  comiteId: string | null;
  phase: string | null;
  relatedResourceId: string | null;
  aapId: string | null;
  controlesValidite?: ControlesValiditeDocument;
}

export class ControlesValiditeDocument implements ControlesValiditeDocumentInterface {
  statut?: EnumValidation;
  motif?: EnumDocumentInvalide;
  commentaire?: string;
  dateModification: Date;
  type: string;
}
