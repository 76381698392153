import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ConfirmModalComponent,
  DocumentConfigModel,
  DocumentFileManagerService,
  DocumentInvalideModalComponent,
  DocumentProjet,
  DocumentService,
  EnumDocumentInvalide,
  EnumValidation,
  ShowToastrService,
  StructureValidationFunction,
  TextSimpleInputModalComponent,
} from '@shared-ui';
import { doc } from 'prettier';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'lib-tuile-document-to-validate',
  templateUrl: './tuile-document-to-validate.component.html',
  styleUrl: './tuile-document-to-validate.component.scss',
})
export class TuileDocumentToValidateComponent implements OnInit {
  @Input() document: DocumentProjet;
  @Input() documentConfig: DocumentConfigModel;
  @Input() statut: EnumValidation | undefined;
  @Input() canUserWrite = false;
  @Input() motifDocumentInvalide: EnumDocumentInvalide | undefined;
  @Input() commentaireDocumentInvalide: string;
  @Input() isSiteAdmin? = true;

  @Output() documentStatutChange = new EventEmitter<DocumentValidation>();
  @Output() deleteDocChange = new EventEmitter<DocumentProjet>();

  invalideChecked: boolean | null;
  valideChecked: boolean | null;

  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];
  readonly INVALIDE = (EnumValidation as any)[EnumValidation.INVALIDE.toString()];

  protected readonly doc = doc;
  protected readonly EnumDocumentInvalide = EnumDocumentInvalide;
  protected readonly EnumValidation = EnumValidation;

  constructor(
    public structureValidationFunction: StructureValidationFunction,
    private readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    private readonly documentFileManagerService: DocumentFileManagerService,
    private readonly showToastrService: ShowToastrService,
    private readonly documentService: DocumentService
  ) {}

  ngOnInit() {
    this.invalideChecked = this.document?.controlesValidite?.statut === this.INVALIDE;
    this.valideChecked = this.document?.controlesValidite?.statut === this.VALIDE;
  }

  downloadDocument(document: DocumentProjet) {
    this.documentFileManagerService.downloadDocument(document, this.isSiteAdmin).pipe(take(1)).subscribe();
  }

  openConfirmationDeleteModal(document: DocumentProjet) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce document.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteDocument(document);
        }
      });
  }

  deleteDocument(document: DocumentProjet): void {
    this.documentService
      .deleteDocument(document.projetId, document.id)
      .pipe(
        take(1),
        switchMap(() => this.documentFileManagerService.deleteDocument(document))
      )
      .subscribe({
        next: () => {
          this.showToastrService.success('Le document a bien été supprimé.');
          this.deleteDocChange.emit(document);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  changeDocumentStatut(statut: EnumValidation | undefined) {
    this.statut = statut;
    this.documentStatutChange.emit({
      statut: statut,
      commentaire: statut === EnumValidation.toKey(EnumValidation.INVALIDE) ? this.commentaireDocumentInvalide : undefined,
      motif: statut === EnumValidation.toKey(EnumValidation.INVALIDE) ? this.motifDocumentInvalide : undefined,
    });
    this.updateRadioChecked();
  }

  private updateRadioChecked() {
    this.invalideChecked = null;
    this.valideChecked = null;
    this.cd.detectChanges();

    const isInvalid = this.statut === this.INVALIDE;
    const isValid = this.statut === this.VALIDE;

    this.invalideChecked = isInvalid;
    this.valideChecked = isValid;
    this.cd.detectChanges();
  }

  openDocumentInvadeBox($event: Event) {
    $event.stopPropagation();

    const dialogRef = this.dialog.open(TextSimpleInputModalComponent, {
      data: {
        fieldInput: this.commentaireDocumentInvalide,
        title: 'Commentaire',
        description: 'Commentaire',
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
        deleteMessage: 'Souhaitez vous supprimer le commentaire ?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.commentaireDocumentInvalide = result;
        this.documentStatutChange.emit({ statut: this.statut, commentaire: this.commentaireDocumentInvalide });
      }
    });
  }

  openInvalidateModal() {
    const dialogRef = this.dialog.open(DocumentInvalideModalComponent, {
      data: {
        fieldInput: this.commentaireDocumentInvalide,
        motif: this.motifDocumentInvalide,
        title: 'Document Invalide',
        description: 'Vous pouvez ajouter un commentaire',
        description2: "Choisir la raison d'invalidité du document",
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
        deleteMessage: 'Souhaitez vous supprimer le commentaire ?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.commentaireDocumentInvalide = result.finalValue;
        this.motifDocumentInvalide = result.motif;
        this.changeDocumentStatut(this.INVALIDE);
      } else {
        this.updateRadioChecked();
      }
    });
  }
}

export interface DocumentValidation {
  statut: EnumValidation | undefined;
  commentaire?: string;
  motif?: EnumDocumentInvalide;
}
