@if(modalData.title){
<h2 id="modal-title" class="modal__title">{{ modalData.title }}</h2>
} @if (modalData.icon){
<div id="modal-icon" class="modal__title-icon">
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
    <defs>
      <filter id="blackicon">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g filter="url(#blackicon)" transform="translate(-1145 -139) translate(302 72)">
        <path
          fill="#BE0E25"
          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zM4 6h6c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1zm6.5-5L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
          transform="translate(843 67)"
        />
      </g>
    </g>
  </svg>
</div>
}

<div
  id="modal-body"
  class="modal__content"
  [style.font-weight]="modalData.boldContent ? 'bold' : 'normal'"
  [innerHTML]="modalData.description"
></div>

<div id="modal-btn" class="modal__actions">
  @if(modalData.textReturnButton){
  <button class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  }
  <button class="ds-btn ds-btn--primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
