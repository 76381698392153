<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="inputForm">
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="!inputForm.controls['inputField'].valid && inputForm.controls['inputField'].touched"
  >
    <label *ngIf="modalData.description" for="input-field" class="ds-input-group__label">{{ modalData.description }}</label>

    <input
      id="input-field"
      name="input-field"
      type="text"
      [placeholder]="modalData.placeholder"
      class="ds-input-group__input"
      formControlName="inputField"
      type="text"
      #textInput
    />

    <!-- Icône et message d'erreur -->
    <ng-container id="error-input-field" *ngIf="!inputForm.controls['inputField']?.valid && inputForm.controls['inputField']?.touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le champ</div>
    </ng-container>
    <ng-container
      id="error-input-field"
      *ngIf="inputForm.controls['inputField']?.valid && inputForm.controls['inputField']?.touched && existeProduit"
    >
      <div class="ds-input-group__feedback">Ce produit existe déjà</div>
    </ng-container>
  </div>

  <div
    class="ds-input-group"
    *ngIf="modalData?.fieldNumber === null"
    [class.ds-input-group--error]="!inputForm.controls['inputFieldNumber'].valid && inputForm.controls['inputFieldNumber'].touched"
  >
    <label *ngIf="modalData.description" for="input-field" class="ds-input-group__label">{{ modalData.descriptionNumber }}</label>

    <input
      id="input-field-number"
      name="input-field-number"
      type="number"
      [placeholder]="modalData.placeholderNumber"
      class="ds-input-group__input"
      formControlName="inputFieldNumber"
      type="number"
      #textInputNumber
    />

    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-input-field-number"
      *ngIf="!inputForm.controls['inputFieldNumber']?.valid && inputForm.controls['inputFieldNumber']?.touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une année</div>
    </ng-container>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="saveModal()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
