export enum EnumPermissionUtilisateur {
  PARAMETRAGE_WRITE = 'PARAMETRAGE_WRITE',
  PROJET_WRITE = 'PROJET_WRITE',

  PROGRAMME_ADD = 'PROGRAMME_ADD',
  PROGRAMME_UPDATE = 'PROGRAMME_UPDATE',
  PROGRAMME_DELETE = 'PROGRAMME_DELETE',
  AAP_ADD = 'AAP_ADD',
  AAP_UPDATE = 'AAP_UPDATE',
  AAP_PROJET_APERCU_VALIDER = 'AAP_PROJET_APERCU_VALIDER',
  AAP_PROJET_APERCU_REJETER = 'AAP_PROJET_APERCU_REJETER',

  PROJET_INFO_UPSERT = 'PROJET_INFO_UPSERT',
  PROJET_INFO_UPLOAD_DOCUMENT = 'PROJET_INFO_UPLOAD_DOCUMENT',
  PROJET_CONSORT_ADD_STRUCT = 'PROJET_CONSORT_ADD_STRUCT',
  PROJET_CONSORT_UPDATE_STRUCT = 'PROJET_CONSORT_UPDATE_STRUCT',
  PROJET_CONSORT_DELETE_STRUCT = 'PROJET_CONSORT_DELETE_STRUCT',
  PROJET_CONSORT_STRUCT_ADD_CONTACT = 'PROJET_CONSORT_STRUCT_ADD_CONTACT',
  PROJET_CONSORT_STRUCT_UPDATE_CONTACT = 'PROJET_CONSORT_STRUCT_UPDATE_CONTACT',
  PROJET_CONSORT_STRUCT_DELETE_CONTACT = 'PROJET_CONSORT_STRUCT_DELETE_CONTACT',

  PROJET_DELETE_DOCUMENT = 'PROJET_DELETE_DOCUMENT',
  AAP_ADD_FILE = 'AAP_ADD_FILE',
  AAP_RECORD = 'AAP_RECORD',
  AAP_ADD_AUTRESDATES = 'AAP_ADD_AUTRESDATES',

  PROJET_EVALUATION_WRITE = 'PROJET_EVALUATION_WRITE',
  PROJET_EVALUATION_WRITE_ALL = 'PROJET_EVALUATION_WRITE_ALL',
  PROJET_EVALUATION_READ = 'PROJET_EVALUATION_READ',
  PROJET_EVALUATION_READ_ALL = 'PROJET_EVALUATION_READ_ALL',

  PROJET_AUDITION_WRITE = 'PROJET_AUDITION_WRITE',
  PROJET_AUDITION_WRITE_ALL = 'PROJET_AUDITION_WRITE_ALL',
  PROJET_AUDITION_READ = 'PROJET_AUDITION_READ',
  PROJET_AUDITION_READ_ALL = 'PROJET_AUDITION_READ_ALL',

  PROJET_DEMANDECORRECTION_WRITE = 'PROJET_DEMANDECORRECTION_WRITE',
  PROJET_DEMANDECORRECTION_READ = 'PROJET_DEMANDECORRECTION_READ',
  PROJET_DEMANDE_COMPLEMENT_WRITE = 'PROJET_DEMANDE_COMPLEMENT_WRITE',
  PROJET_DEMANDE_COMPLEMENT_READ = 'PROJET_DEMANDE_COMPLEMENT_READ',

  WKF_DEPOT = 'WKF_DEPOT',
  WKF_PREDEPOT = 'WKF_PREDEPOT',
  WKF_INSTRUCTION = 'WKF_INSTRUCTION',
  WKF_CONTRACTUALISATION = 'WKF_CONTRACTUALISATION',
  WKF_PREDEPOT_EVALUATION = 'WKF_PREDEPOT_EVALUATION',
  WKF_PREDEPOT_AUDITION = 'WKF_PREDEPOT_AUDITION',
  WKF_DEPOT_ENCOURS = 'WKF_DEPOT_ENCOURS',
  WKF_DEPOT_ANALYSE = 'WKF_DEPOT_ANALYSE',

  EXTRACTION_PROJET_PARTENAIRE = 'EXTRACTION_PROJET_PARTENAIRE',

  PROJET_VERROUILLAGE_READ = 'PROJET_VERROUILLAGE_READ',
  PROJET_VERROUILLAGE_WRITE = 'PROJET_VERROUILLAGE_WRITE',

  PROJET_ELIGIBILITE_READ = 'PROJET_ELIGIBILITE_READ',
  PROJET_ELIGIBILITE_WRITE = 'PROJET_ELIGIBILITE_WRITE',

  PROJET_CONTRACTUALISATION_READ = 'PROJET_CONTRACTUALISATION_READ',
  PROJET_CONTRACTUALISATION_WRITE = 'PROJET_CONTRACTUALISATION_WRITE',

  PROJET_INSTRUCTION_READ = 'PROJET_INSTRUCTION_READ',
  PROJET_INSTRUCTION_READ_ALL = 'PROJET_INSTRUCTION_READ_ALL',
  PROJET_INSTRUCTION_WRITE = 'PROJET_INSTRUCTION_WRITE',
  PROJET_INSTRUCTION_WRITE_ALL = 'PROJET_INSTRUCTION_WRITE_ALL',

  STRUCTURE_WRITE_ALL = 'STRUCTURE_WRITE_ALL',
  STRUCTURE_WRITE = 'STRUCTURE_WRITE',
  FEATURE_FLIPPING = 'FEATURE_FLIPPING',

  PXL_QLIK_READ = 'PXL_QLIK_READ',
  PROJET_GLOBAL_SEARCH = 'PROJET_GLOBAL_SEARCH',

  RECHERCHE_TRANSVERSE_READ = 'RECHERCHE_TRANSVERSE_READ',

  ACTIVITE_READ = 'ACTIVITE_READ',

  NOTIFICATION_READ = 'NOTIFICATION_READ',

  PROJET_READ = 'PROJET_READ',
  PURGE_READ = 'PURGE_READ',
  PURGE_WRITE = 'PURGE_WRITE',

  AUTOAFFECTATION_PROJET = 'AUTOAFFECTATION_PROJET',

  AAP_READ = 'AAP_READ',
  PROJET_FILTERS = 'PROJET_FILTERS',

  SUIVI_PROJET_READ = 'SUIVI_PROJET_READ',
  SUIVI_PROJET_WRITE = 'SUIVI_PROJET_WRITE',

  PROJET_NOTATION_EVALUATION_WRITE = 'PROJET_NOTATION_EVALUATION_WRITE',
  PROJET_NOTATION_EVALUATION_WRITE_ALL = 'PROJET_NOTATION_EVALUATION_WRITE_ALL',
  PROJET_NOTATION_AUDITION_WRITE = 'PROJET_NOTATION_AUDITION_WRITE',
  PROJET_NOTATION_AUDITION_WRITE_ALL = 'PROJET_NOTATION_AUDITION_WRITE_ALL',
  PROJET_NOTATION_SELECTION_WRITE = 'PROJET_NOTATION_SELECTION_WRITE',
  PROJET_NOTATION_SELECTION_WRITE_ALL = 'PROJET_NOTATION_SELECTION_WRITE_ALL',
}
