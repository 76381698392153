import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import {
  ApprobationSignatureRequest,
  BudgetRequest,
  Contact,
  DemandeRectification,
  InformationSiegeRequest,
  Invitation,
  LieuRD,
  MembreEquipe,
  PrevisionsEconomiques,
  ShowToastrService,
  SignataireInterface,
  Structure,
  SyntheseContractualisationStructure,
} from '@shared-ui';
import { BehaviorSubject, catchError, distinctUntilChanged, map, Observable, of, tap } from 'rxjs';

@Injectable()
export class StructureService {
  structureUrl = environment.apiUrl + 'structures';
  notifUrl = environment.apiNotif;
  private structureSubject = new BehaviorSubject(null);
  private structuresListSubject = new BehaviorSubject(null);
  private syntheseContractualisationStructuresSubject: BehaviorSubject<SyntheseContractualisationStructure[]> = new BehaviorSubject(null);

  public setStructureObservable(structure: Structure): void {
    this.structureSubject.next(structure);
  }

  public getStructureObservable(): Observable<Structure> {
    return this.structureSubject.asObservable();
  }

  public setStructuresListObservable(structures: Structure[]): void {
    this.structuresListSubject.next(structures);
  }

  public setUniqueStructureListObservable(structure: Structure, structures: Structure[]): void {
    const index = structures.findIndex(struct => {
      return struct.id === structure.id;
    });
    const newList = structures;
    newList[index] = structure;
    this.setStructuresListObservable(newList);
  }

  public getStructuresListObservable(): Observable<Structure[]> {
    return this.structuresListSubject.asObservable();
  }

  constructor(private httpClient: HttpClient, private showToastrService: ShowToastrService) {}

  getStructures(): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl + '/infos', { observe: 'response' });
  }

  getStructuresByIdProject(idProject: string): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl + '/infos', {
      params: { idProjet: idProject },
      observe: 'response',
    });
  }

  addStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    return this.httpClient.post<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    if (structure.lieuRD?.siret) {
      structure.lieuRD.siret = structure.lieuRD.siret.replace(/\s/g, '');
    }
    if (structure.budgetPreDepot?.montant) {
      structure.budgetPreDepot.montant = structure.budgetPreDepot.montant ?? '0';
    }
    if (structure.budgetDepot?.montant) {
      structure.budgetDepot.montant = structure.budgetDepot.montant ?? '0';
    }

    return this.httpClient.put<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateMembreEquipeStructure(structureId: string, membreEquipe: MembreEquipe): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + structureId + '/equipe', JSON.stringify(membreEquipe), {
      observe: 'response',
    });
  }

  getStructureById(idStructure: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.get<Structure>(this.structureUrl + '/' + idStructure + '/infos', { observe: 'response' });
  }

  deleteStructure(idStructure: string, structure: Structure): Observable<object> {
    return this.httpClient.request('delete', this.structureUrl + '/' + idStructure, {
      body: JSON.stringify(structure),
      observe: 'response',
    });
  }

  addContactStructure(idStructure: string, contact: Contact): Observable<HttpResponse<Structure>> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts', JSON.stringify(contact), {
      observe: 'response',
    });
  }

  updateContactStructure(idStructure: string, contact: Contact, idContact: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact, JSON.stringify(contact), {
      observe: 'response',
    });
  }

  deleteContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.delete<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact);
  }

  getInvitationsContactsStructure(idStructure: string): Observable<HttpResponse<Invitation[]>> {
    return this.httpClient.get<Invitation[]>(this.structureUrl + '/' + idStructure + '/contacts/invitations', {
      observe: 'response',
    });
  }

  updateStructureDemandeDeRectification(
    structureId: string,
    demandeRectification: DemandeRectification
  ): Observable<HttpResponse<Structure>> {
    return this.httpClient.patch<Structure>(
      this.structureUrl + '/' + structureId + '/adresse/demande-rectification',
      JSON.stringify(demandeRectification),
      {
        observe: 'response',
      }
    );
  }

  disableContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/desactiver', null);
  }

  reinvitationContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/reactiver', null);
  }

  saveSignataire(idStructure: string, signataire: SignataireInterface): Observable<HttpResponse<SignataireInterface>> {
    return this.httpClient.patch<SignataireInterface>(
      `${this.structureUrl}/${idStructure}/contractualisation/signataires`,
      JSON.stringify(signataire),
      {
        observe: 'response',
      }
    );
  }

  getSignataires(idStructure: string): Observable<HttpResponse<SignataireInterface[]>> {
    return this.httpClient.get<SignataireInterface[]>(`${this.structureUrl}/${idStructure}/contractualisation/signataires`, {
      observe: 'response',
    });
  }

  getSyntheseContractualisationStructures(projetId: string): Observable<SyntheseContractualisationStructure[]> {
    return this.httpClient
      .get<SyntheseContractualisationStructure[]>(`${this.structureUrl}/contractualisation/synthese`, {
        params: { projetId: projetId },
        observe: 'response',
      })
      .pipe(
        map(response => response.body || []),
        tap(syntheses => this.syntheseContractualisationStructuresSubject.next(syntheses)),
        catchError((err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          return of([] as SyntheseContractualisationStructure[]);
        })
      );
  }

  loadSyntheseContractualisationStructuresSubject(): Observable<SyntheseContractualisationStructure[]> {
    return this.syntheseContractualisationStructuresSubject.asObservable().pipe(distinctUntilChanged());
  }

  updateBudget(idStructure: string, budgetRequest: BudgetRequest): Observable<HttpResponse<Structure>> {
    if (budgetRequest.budget?.montant) {
      budgetRequest.budget.montant = budgetRequest.budget.montant ?? '0';
    }
    return this.httpClient.patch<Structure>(`${this.structureUrl}/${idStructure}/structure-budget`, JSON.stringify(budgetRequest), {
      observe: 'response',
    });
  }

  updateLieuRD(idStructure: string, lieuRD: LieuRD): Observable<HttpResponse<Structure>> {
    if (lieuRD.siret) {
      lieuRD.siret = lieuRD.siret.replace(/\s/g, '');
    }
    return this.httpClient.patch<Structure>(`${this.structureUrl}/${idStructure}/lieu-rd`, JSON.stringify(lieuRD), {
      observe: 'response',
    });
  }

  updateInformationSiege(idStructure: string, informationSiege: InformationSiegeRequest): Observable<HttpResponse<Structure>> {
    if (informationSiege.siret) {
      informationSiege.siret = informationSiege.siret.replace(/\s/g, '');
    }
    return this.httpClient.patch<Structure>(`${this.structureUrl}/${idStructure}/informations-siege`, JSON.stringify(informationSiege), {
      observe: 'response',
    });
  }
  updatePrevisionsEconomiques(
    structureId: string,
    previsionEconomique: PrevisionsEconomiques
  ): Observable<HttpResponse<PrevisionsEconomiques>> {
    return this.httpClient.put<PrevisionsEconomiques>(
      this.structureUrl + '/' + structureId + '/previsions-economiques',
      JSON.stringify(previsionEconomique),
      {
        observe: 'response',
      }
    );
  }

  approvalSignature(idStructure: string, approvalResponse: ApprobationSignatureRequest): Observable<HttpResponse<Structure>> {
    return this.httpClient.patch<Structure>(
      `${this.structureUrl}/${idStructure}/contractualisation/approbation-signature`,
      JSON.stringify(approvalResponse),
      {
        observe: 'response',
      }
    );
  }
}
